<template>
  <!-- 课程详情页 -->
  <div style="overflow: auto;background: #FFFFFF;">
    <div class="course-details">
      <div class="course-details-title" @click="$router.go(-1)">
        公告详情
      </div>

      <div class="course-details-content">
        <div class="course-details-content-title">
          <div class="content-title-one">{{Msg.title}}</div>
          <div class="content-title-two">
            <span>时间：{{Msg.created}}</span>
            <span>|</span>
            <span>浏览量：{{Msg.hits}}</span>
          </div>
        </div>
        <div class="course-details-content-text" v-html="Msg.content">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { httpGet } from "@/utils/httpRequest";
export default {
  name: "BulletinDetails",
  data(){
    return {
      Msg: {}
    }
  },

  methods:{
    getNoticeDetailsMsg(){
      httpGet("/news-info/detail",
      {
        newsId: this.$route.params.noticeId
      },
      (res)=>{
        // console.log(res.data)
        this.Msg = res.data
      },
      (err)=>{
        // // console.log(err)
        throw err
      })
    }
  },
  mounted(){
    this.getNoticeDetailsMsg()
  }
}
</script>

<style lang="scss" scoped>
.course-details {
  padding: 0 3.38624%;
  // box-shadow: 0 2px 4px 0 rgba(3, 27, 78, 0.06);
  border-radius: 2px 2px 2px 2px;
  height: 49.5rem;

  .course-details-grade {
    width: 100%;
    height: 6rem;
    background: #F5F8FA;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 1.25rem;

    .line {
      width: 1px;
      height: 2rem;
      background: #D9D9D9;
    }

    .title {
      height: 1.5rem;
      font-size: 0.75rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #667280;
      line-height: 1.5rem;
    }

    .grade {
      height: 1.5rem;
      font-size: 1.5rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 1.5rem;
    }
  }

  .course-details-content {
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    margin-top: 1.25rem;
    position: relative;
    padding-bottom: 30px;
    .course-details-content-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 63px;
      margin-bottom: 32px;
      //background-color: #d0d0d0;

      .content-title-one {
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }

      .content-title-two {
        display: flex;
        justify-content: space-between;
        width: 222px;
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #667280;
      }
    }
    .course-details-content-text {
      text-align: left;
      font-size: 18px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #3E454D;
    }

  }

  /* 当前状态颜色 */
  ::v-deep .el-progress-bar__outer {
    background: rgba(0, 0, 0, 0.23);
  }

  ::v-deep .el-progress-bar {
    height: 0.75rem;
  }

  ::v-deep .el-progress__text {
    display: inline-block;
    vertical-align: middle;
    height: 1.5rem;
    font-size: 0.8125rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #3E454D;
    margin-left: 0.625rem;
    line-height: 1;
  }

  .course-details-title {
    cursor: pointer;
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    font-size: 1.25rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    border-bottom: 0.0625rem solid #EDEFF2;
  }

  .course-details-title::before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('~@/assets/img/pre/icon-arrowL-24.png');
    background-size: 100% 100%;
    margin-right: 0.625rem;
  }
}
</style>